.blog-page {
    min-height: 100vh;
    background: linear-gradient(135deg, #1a1a1a 0%, #2d2d2d 100%);
    color: #ffffff;
    padding: 2rem;
}

.blog-content {
    max-width: 1000px;
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.3);
    padding: 2rem;
    border-radius: 8px;
}

.blog-header {
    text-align: center;
    margin-bottom: 3rem;
}

.blog-header h1 {
    color: #ffd700;
    font-size: 2.5rem;
    margin-bottom: 1rem;
    text-shadow: 0 0 10px rgba(255, 215, 0, 0.3);
}

.blog-section {
    margin: 3rem 0;
    padding: 2rem;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
}

.blog-section h2 {
    color: #ffd700;
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
}

.feature-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
}

.feature-card {
    padding: 1.5rem;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.feature-card:hover {
    transform: translateY(-5px);
}

.feature-card h3 {
    color: #ffd700;
    margin-bottom: 1rem;
}

.match-stats {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
}

.stat-card {
    padding: 1rem;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    text-align: center;
}

.stat-card h3 {
    color: #ffd700;
    margin-bottom: 0.5rem;
}

.tips-list {
    list-style: none;
    padding: 0;
}

.tips-list li {
    margin-bottom: 1rem;
    padding-left: 1.5rem;
    position: relative;
}

.tips-list li:before {
    content: "→";
    position: absolute;
    left: 0;
    color: #ffd700;
}

.update-list {
    display: grid;
    gap: 1.5rem;
}

.update-item {
    padding: 1.5rem;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.update-date {
    color: #ffd700;
    font-size: 0.9rem;
}

.update-item h3 {
    margin: 0.5rem 0;
    color: #ffffff;
}

@media (max-width: 768px) {
    .blog-content {
        padding: 1rem;
    }

    .blog-section {
        padding: 1rem;
    }

    .feature-grid {
        grid-template-columns: 1fr;
    }
} 