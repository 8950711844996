.site-footer {
    margin-top: 4rem;
    padding: 3rem 0;
    background: rgba(0, 0, 0, 0.4);
    border-top: 1px solid rgba(255, 215, 0, 0.1);
    border-radius: 8px;
}

.footer-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
}

.footer-section {
    color: #cccccc;
}

.footer-section h3 {
    color: #ffd700;
    margin-bottom: 1rem;
    font-size: 1.2rem;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 0.5rem;
}

.footer-section a {
    color: #cccccc;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-section a:hover {
    color: #ffd700;
}

/* Policy pages styling */
.policy-page {
    min-height: 100vh;
    background: linear-gradient(135deg, #1a1a1a 0%, #2d2d2d 100%);
    color: #ffffff;
    padding: 2rem;
}

.policy-content {
    max-width: 800px;
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.3);
    padding: 2rem;
    border-radius: 8px;
}

.policy-content h1 {
    color: #ffd700;
    margin-bottom: 1rem;
}

.policy-content section {
    margin: 2rem 0;
}

.policy-content h2 {
    color: #ffd700;
    margin-bottom: 1rem;
    font-size: 1.5rem;
}

.back-link {
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid rgba(255, 215, 0, 0.1);
}

.back-link a {
    color: #ffd700;
    text-decoration: none;
    transition: color 0.3s ease;
}

.back-link a:hover {
    color: #ffffff;
}

@media (max-width: 768px) {
    .footer-content {
        grid-template-columns: 1fr;
        text-align: center;
    }

    .footer-section {
        margin-bottom: 2rem;
    }

    .policy-content {
        padding: 1rem;
    }
} 