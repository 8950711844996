body {
    margin: 0;
    padding: 0;
    background: linear-gradient(135deg, #1a1a1a 0%, #2d2d2d 100%);
    min-height: 100vh;
    overflow-x: hidden;
}

.app {
    min-height: 100vh;
    background: linear-gradient(135deg, #1a1a1a 0%, #2d2d2d 100%);
    color: #ffffff;
    padding: 20px;
}

.main-content {
    max-width: 1200px;
    margin: 0 auto;
}

.fight-header {
    text-align: center;
    margin-bottom: 2rem;
    padding: 2rem;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    border: 1px solid rgba(255, 215, 0, 0.3);
    box-shadow: 0 0 20px rgba(255, 215, 0, 0.1);
    position: relative;
    overflow: hidden;
}

.fight-header::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, rgba(255, 215, 0, 0.1), transparent);
    z-index: 0;
}

.fight-header h1 {
    position: relative;
    font-size: 2.8rem;
    margin-bottom: 0.5rem;
    color: #ffd700;
    text-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
    font-weight: 800;
    letter-spacing: 1px;
}

.event-details {
    font-size: 1.2rem;
    color: #cccccc;
}

.fight-stats {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 1rem;
}

.stat {
    background: rgba(255, 255, 255, 0.1);
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-weight: bold;
}

.game-section {
    margin: 2rem 0;
    background: rgba(0, 0, 0, 0.3);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.fight-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin: 2rem 0;
}

.info-column {
    background: rgba(0, 0, 0, 0.3);
    padding: 2rem;
    border-radius: 8px;
}

.info-column h2 {
    color: #ffd700;
    margin-bottom: 1rem;
}

.info-column ul {
    list-style: none;
    padding: 0;
}

.info-column li {
    margin-bottom: 0.8rem;
    padding-left: 1.5rem;
    position: relative;
}

.info-column li:before {
    content: "→";
    position: absolute;
    left: 0;
    color: #ffd700;
}

.fighter-profiles {
    margin: 2rem 0;
    padding: 2rem;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 8px;
}

.fighter-profiles h2 {
    text-align: center;
    color: #ffd700;
    margin-bottom: 2rem;
}

.profiles-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.profile {
    padding: 1.5rem;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    text-align: center;
    transition: transform 0.3s ease;
}

.profile:hover {
    transform: translateY(-5px);
}

.fighter-image {
    width: 100%;
    height: 400px;
    margin-bottom: 1.5rem;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.profile-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.profile.jake .profile-img {
    object-position: center top;
}

.profile.tyson .profile-img {
    object-position: center 20%;
}

.profile h3 {
    color: #ffd700;
    margin-bottom: 1rem;
    font-size: 1.8rem;
}

.profile .stats {
    display: grid;
    gap: 0.8rem;
    color: #ffffff;
    font-size: 1.1rem;
}

@media (max-width: 768px) {
    .profiles-container {
        grid-template-columns: 1fr;
    }

    .fighter-image {
        height: 300px;
    }
}

@media (max-width: 480px) {
    .fighter-image {
        height: 250px;
    }
}

.error-container {
    padding: 2rem;
    background: rgba(255, 0, 0, 0.1);
    border-radius: 8px;
    margin: 2rem;
    color: #ffffff;
}

@media (max-width: 768px) {
    .fight-info {
        grid-template-columns: 1fr;
    }

    .profiles-container {
        grid-template-columns: 1fr;
    }

    .fight-stats {
        flex-direction: column;
        gap: 1rem;
    }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.site-footer {
    margin-top: 4rem;
    padding: 3rem 0;
    background: rgba(0, 0, 0, 0.4);
    border-top: 1px solid rgba(255, 215, 0, 0.1);
    border-radius: 8px;
}

.footer-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
}

.footer-section {
    color: #cccccc;
}

.footer-section h3 {
    color: #ffd700;
    margin-bottom: 1rem;
    font-size: 1.2rem;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 0.5rem;
}

.footer-section a {
    color: #cccccc;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-section a:hover {
    color: #ffd700;
}

@media (max-width: 768px) {
    .footer-content {
        grid-template-columns: 1fr;
        text-align: center;
    }

    .footer-section {
        margin-bottom: 2rem;
    }
}
